<template>
  <Dialog ref="dialog" :config="config">
    <div class="main">
      <h1>Política de privacidad</h1>
      <p>
        RAKUMART concede gran importancia a la privacidad de los usuarios y la
        privacidad es su derecho más importante. Al utilizar nuestros servicios,
        podemos recopilar y utilizar su información relevante. Este documento
        pretende explicar cómo funciona nuestra "Política de privacidad" cómo
        recopilamos, usamos, almacenamos, administramos y protegemos esta
        información cuando utiliza nuestros servicios.
      </p>
      <p>
        Nuestra "Política de Privacidad" está estrechamente relacionada con los
        servicios que utiliza, por lo que esperamos que la lea detenidamente y
        tome las decisiones que considere oportunas de acuerdo con las pautas de
        esta "Política de Privacidad" cuando sea necesario. Para el vocabulario
        técnico relevante involucrado en esta "Política de privacidad", tratamos
        de ser concisos y directos, y proporcionamos enlaces a explicaciones
        adicionales para su mejor comprensión.
      </p>
      <p>
        Al comenzar a usar o continuar usando nuestros servicios, usted acepta
        nuestra recopilación, uso, almacenamiento y administración de su
        información relevante de acuerdo con esta "Política de privacidad".
      </p>
      <h2>1. Información de privacidad</h2>
      <p>
        En la información de privacidad, "información personal" se refiere a
        información sobre personas, como nombres, direcciones, números de
        teléfono, cuentas sociales, etc., tal y como se define en la Ley de
        protección de información personal. Se refiere a la información que
        puede identificar a un individuo específico por descripción.
      </p>
      <p>
        En la información de privacidad, "información histórica e información
        característica" se refiere a información distinta de la "información
        personal" citada anteriormente, como servicios utilizados, productos
        comprados, historial de páginas/anuncios vistos, palabras clave
        buscadas, fecha y hora de uso, método de uso , entorno de uso, código
        postal y género, ocupación, edad, dirección IP del afiliado, información
        de cookies, información de ubicación, información de identificación
        personal del terminal, etc.
      </p>
      <h2>2. Información que podemos recopilar</h2>
      <p>
        Cuando brindamos servicios, podemos recopilar, almacenar y utilizar la
        siguiente información sobre usted. Si no proporciona información
        relevante, es posible que no pueda registrarse como nuestro usuario o
        disfrutar de algunos de los servicios que brindamos, o es posible que no
        pueda lograr el efecto deseado de los servicios relevantes.
      </p>
      <h3>2.1 Información que proporciona:</h3>
      <p>
        Información personal relevante que nos proporciona cuando registra una
        cuenta o utiliza nuestros servicios, como número de teléfono, correo
        electrónico o número de tarjeta bancaria, etc., la información
        compartida que proporciona a las partes relacionadas con el negocio a
        través de nuestros servicios y su uso de nuestros servicios, información
        almacenada en ese momento.
      </p>
      <h3>
        2.2 Su información compartida por partes relacionadas con el negocio
      </h3>
      <p>
        Información compartida sobre usted proporcionada por afiliados
        comerciales al usar nuestros servicios.
      </p>
      <h3>2.3 Información que obtenemos</h3>
      <p>
        Podemos recopilar la siguiente información cuando utiliza el servicio:
        <ul>
            <li>● Información de registro: Se refiere a la información técnica que el sistema puede recopilar automáticamente a través de cookies u otros medios cuando utiliza nuestros servicios, incluida: información del dispositivo o software, como su dispositivo móvil, navegador web u otros programas utilizados para acceder a nuestros servicios. La configuración e información proporcionada, su dirección IP y la versión e identificador de dispositivo utilizado por su dispositivo móvil.
</li>
<li>
    ● Información buscada o vista mientras usa nuestros servicios, como los términos de búsqueda web que usa y su historial de navegación mientras usa nuestros servicios y otra información solicitada.

</li>
        </ul>
      </p>
      <h2>
          3. Los fines para los que recopilamos y utilizamos información personal son los siguientes:

      </h2>
      <h3>
          3.1 Información de registro

      </h3>
      <p>
          Como nombre, dirección, información de contacto, método de pago, biblioteca de gestión de productos básicos, etc.

      </p>
      <p>Propósito: Es conveniente que los afiliados verifiquen y corrijan sus datos de registro y verifiquen el estado de uso.
</p>
<h3>3.2 Dirección de correo electrónico
</h3>
<p>
    Finalidad: Se utiliza cuando es necesario utilizar una dirección de correo electrónico para notificar o contactar a los afiliados, o para enviar información de productos y comunicaciones comerciales a los afiliados.

</p>
<h3>3.3 Uso de nombres
</h3>
<p>Finalidad: Es necesario verificar la verdadera identidad del afiliado a través de datos como dirección y número de teléfono.
</p>
<h3>3.4 Información de pago
</h3>
<p>Finalidad: Es necesario determinar el importe a cobrar a los afiliados de acuerdo al precio, cantidad, importe de la factura, método de transporte, dirección de entrega y demás información del producto adquirido.
</p>
<h3>3.5 Rechazamos afiliados que vulneren los términos de uso de este servicio
</h3>
<p>Incluyendo, entre otros, el incumplimiento del pago de bienes, negarse a pagar varios impuestos que el usuario está obligado a pagar, dañar los derechos e intereses de otros afiliados, infringir maliciosamente los derechos de propiedad intelectual de terceros y usar intencionalmente el servicio para fines fraudulentos o propósitos irrazonables.
</p>
<p>
    Tomaremos los medios técnicos apropiados para garantizar que pueda acceder, actualizar y corregir su información de registro u otra información personal que proporcione al utilizar nuestros servicios. Al acceder, actualizar, corregir y eliminar la información antes mencionada, es posible que le solicitemos que se autentique para garantizar la seguridad de la cuenta.

</p>
<h2>4. Adquisición y uso de registros históricos e información relacionada
</h2>
<h3>4.1 Información que obtenemos y almacenamos
</h3>
<p>Recopilamos y usamos su información mediante tecnologías como cookies* y JavaScript, y almacenamos esa información como información de registro.
Tales como la dirección IP del usuario, las URL a las que accede el usuario, el contenido, las palabras clave de búsqueda (en lo sucesivo, "historial"). El historial obtenido y la información relacionada se pueden utilizar para mejorar nuestros servicios y para fines de datos estadísticos. Estas historias o información relacionada no contienen ninguna información personal.
</p>
<h3>4.2 Analizar su uso de nuestros servicios
</h3>
<p>Por ejemplo, podemos usar cookies para comprender qué actividades utiliza nuestros servicios y determinar qué páginas web o servicios son más populares para usted.
  
</p>
<p>Puede rechazar o administrar las cookies a través de la configuración de su navegador. Tenga en cuenta que si deshabilita las cookies, es posible que no pueda disfrutar de la mejor experiencia de servicio y que algunos servicios no funcionen correctamente.
</p>
<h3>4.3 Determinación de Información Personal
</h3>
<p>No juzgamos el historial de comportamiento o la información relacionada solo como información personal porque no es información que pueda identificar a un individuo específico.
</p>
<h2>5. Acerca de las cookies
</h2>
<p>Una cookie es información de identificación temporal en su computadora cuando utiliza un sitio web a través de su navegador web. Este es un mecanismo de guardar como destino para iniciar sesión automáticamente la próxima vez que inicie sesión, sin tener que iniciar sesión nuevamente. Puede rechazar las cookies configurando su navegador para ello, pero al rechazar las cookies, la operatividad se vuelve pobre. El uso de varios servicios en Internet está restringido, como la imposibilidad de usar servicios que requieren autenticación y puede que no funcionen correctamente.
</p>
<h2>6. Suministro de información personal a terceros
</h2>
<h3>6.1 Circunstancias de proporcionar información personal
</h3>
<p>Generalmente, no proporcionamos información personal a terceros sin el consentimiento del individuo. En los siguientes casos, la información personal puede ser proporcionada sin el consentimiento del individuo en la medida en que no vulnere la ley.
    <ul>
        <li>● Cuando el afiliado determina el riesgo de afectar adversamente a otros.
</li>
<li>● Es difícil obtener el consentimiento previo cuando es necesario para proteger la vida, el cuerpo o la propiedad de otros, mejorar la salud pública o mantener seguros a los niños.
</li>
<li>● Actos realizados por organismos estatales o locales públicos o encomendados de conformidad con las leyes y reglamentos.
</li>
<li>● Sobre solicitudes de divulgación de información personal de colegios de abogados, centros de consumo u organismos similares.
</li>
<li>● Donde la divulgación o provisión esté permitida por ley.
</li>
<li>● Cuando la información personal deba proporcionarse debido a la sucesión empresarial por fusión u otras razones, se tratará en el ámbito de la finalidad de uso antes de la sucesión.
</li>
    </ul>
</p>
<h3>6.2 Descargo de responsabilidad sobre el suministro de información personal a terceros
</h3>
<p>
    <ul>
        <li>● Cuando la información de identificación (ID, contraseña, etc.) sea filtrada por el usuario a otros.
</li>
        <li>● Cuando los usuarios proporcionan información de nuestro sitio web a sitios web de terceros.
</li>
    </ul>
</p>
<h2>7. Divulgación de información personal
</h2>
<p>
    Revelamos su información personal a los propios usuarios de acuerdo con la solicitud del usuario. Sin embargo, si la información divulgada incluye algo de lo siguiente, es posible que no la divulguemos en su totalidad y si decidimos no divulgar, se lo notificaremos.

</p>
<h3>7.1 Existe el riesgo de dañar la vida, el cuerpo, la propiedad u otros derechos de los demás.
</h3>
<h3>7.2 Existe el riesgo de impedir materialmente el desarrollo normal de nuestro negocio.
</h3>
<h3>7.3 Otras infracciones de leyes y reglamentos a pesar de lo dispuesto en el párrafo anterior, en principio, no divulgaremos otra información que no sea información personal, como información histórica e información característica.
</h3>
<h2>8. Corrección y eliminación de información personal
</h2>
<p>Si la información personal del afiliado de la empresa es incorrecta, el afiliado puede solicitar a la empresa que corrija o elimine la información personal de acuerdo con los procedimientos establecidos por la empresa. Si la empresa recibe una solicitud de un afiliado y determina que es necesario responder a la solicitud, corregiremos o eliminaremos la información personal y se lo notificaremos al afiliado.
</p>
<h2>9. Suspensión del uso de información personal, etc.
</h2>
<p>Debido al uso de información personal más allá del alcance del propósito de uso o porque la información se obtuvo por medios fraudulentos, la empresa suspenderá o eliminará la información personal de la persona, realizará las investigaciones necesarias y en función de los resultados, suspenderá el uso de información personal y notificará al afiliado sobre esta situación.
</p>
<h2>10. Cambios en la política de privacidad
</h2>
<p>Podemos revisar los términos de esta Política de privacidad de vez en cuando, y dichas revisiones forman parte de esta Política de privacidad. Si dichas revisiones representan una reducción material de sus derechos bajo esta Política de privacidad, le notificaremos colocando un aviso destacado en la página de inicio o enviándole un correo electrónico o de otra manera antes de que las revisiones entren en vigencia. En este caso, si continúa utilizando nuestros servicios, acepta estar sujeto a esta Política de privacidad revisada.
</p>
<p class="time">1 de enero de 2022
</p>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "../../components/public/Dialog";
export default {
  data() {
    return {
      config: {
        top: "20vh",
        width: "1400px",
        title: this.$fanyi("RAKUMART的用户协议和隐私政策"),
        btnTxt: [this.$fanyi("同意"), this.$fanyi("取消")],
      },
    };
  },
  components: {
    Dialog,
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang='scss' scoped='scoped'>
@import "../../css/mixin.scss";
.main {
  h1 {
    font-size: 29px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }
  p {
    text-indent: 1.5cm;
    font-size: 14px;
    line-height: 24px;
    &.time {
      margin-top: 30px;
      text-indent: 0;
    }
    ul {
      text-indent: 0;
      padding-left: 70px;
    }
  }
  h2 {
    margin: 30px 0 20px;
    font-size: 21px;
    font-weight: bold;
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
    text-indent: 0.5cm;
    margin: 10px 0;
  }
}
</style>