<template>
  <div class="obody">
    <HomePageTop></HomePageTop>
    <UserAgreement ref="UserAgreement"></UserAgreement>
    <!-- 注册主体 -->
    <div class="logo-box">
      <!-- logo S -->
      <div class="fl fenge"></div>
      <h2 class="fl">{{ $fanyi("创建新帐户") }}</h2>
      <div class="login fr">
        <span class="oicon">i</span>
        <router-link to="/login">{{ $fanyi("已经有账户了?登录") }}</router-link>
      </div>
      <!-- logo E -->
    </div>
    <!-- 第三步页面 -->
    <div class="registration_complete">
      <div class="registration_completeCon">
        <div class="registration_completeConOpt" style="margin-bottom: 20px">
          <div class="successIcon">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/6627705e16bca.svg"
              alt=""
            />
          </div>
        </div>
        <div class="registration_completeConOpt" style="margin-bottom: 10px">
          <span class="title">{{ $fanyi("注册成功完成") }}</span>
        </div>
        <div class="registration_completeConOpt" style="margin-bottom: 40px">
          <span class="neiRong">{{
            $fanyi("感谢您的注册!现在你可以开始享受Rakumart提供的所有好处了。")
          }}</span>
        </div>
        <div class="registration_completeConOpt">
          <button @click="login">
            {{ $fanyi("访问") }}
          </button>
        </div>
        <div class="jianyi">
          {{ $fanyi("我们建议您花几分钟时间了解更多关于Rakumart的信息:") }}
        </div>
        <div class="staticPageLink">
          <div
            class="staticPageLinkOpt"
            @click="$fun.routerToPage('/quienes-somos')"
          >
            {{ $fanyi("Rakumart是做什么的?") }}
          </div>
          <div
            class="staticPageLinkOpt"
            @click="$fun.routerToPage('/guia-de-usuario')"
          >
            {{ $fanyi("Rakumart是如何运作的?") }}
          </div>
          <div
            class="staticPageLinkOpt"
            @click="$fun.routerToPage('/ventajas')"
          >
            {{ $fanyi("Rakumart的优势是什么?") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomePageTop from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot.vue";
import UserAgreement from "./UserAgreement.vue";
export default {
  data() {},
  // mounted() {
  // 	this.$jsonp('https://pv.sohu.com/cityjson?ie=utf-8').then((res)=>{
  // 	　	//console.log(res);
  // 	})
  // },
  created() {},
  components: {
    HomePageTop,
    UserAgreement,
  },
  methods: {
    login() {
      this.$api
        .logins({
          login_account: sessionStorage.getItem("login_account"),
          password: sessionStorage.getItem("password"),
          client_tool: 1,
        })
        .then((res) => {
          localStorage.setItem("user_token", res.data.user_token);
          sessionStorage.setItem("beginnerTips", true);
          this.$api.EuropegetUserInfo().then((res) => {
            this.$store.commit("userData", res.data);
            this.$forceUpdate();
            this.$fun.routerToPage("/");
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/mixin";

.logo-box {
  background-color: white;
  width: $pageWidth;
  margin: 10px auto 0;
  padding-bottom: 30px;
  border: solid 1px #e8e8e8;
  border-radius: 6px 6px 0 0;
  height: 80px;
  overflow: hidden;
  border-bottom: 1px solid #ddd;

  .fenge {
    width: 1px;
    height: 35px;
    margin: 22px 10px 0 50px;
    background-color: $homePageSubjectColor;
  }

  h2 {
    line-height: 80px;
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }

  .login {
    margin: 30px 50px 0 0;

    &.fr {
      $loginIconSize: 14px;
      .oicon {
        display: inline-block;
        text-align: center;
        margin-right: 5px;
        font-size: 12px;
        line-height: $loginIconSize;
        font-weight: 400;
        color: #ffffff;
        width: $loginIconSize;
        height: $loginIconSize;
        background: $homePageSubjectColor;
        border-radius: 50%;
      }
    }

    span {
      font-size: 18px;
      font-weight: bold;
      color: #999;
    }

    a {
      font-size: 14px;
      font-weight: 400;
      color: #404040;
    }
  }
}

.registration_complete {
  width: 1400px;
  margin: 0 auto 100px;
  text-align: center;
  padding: 0 80px 0;
  background-color: white;

  > .registration_completeCon {
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 80px 0 150px;
    .registration_completeConOpt {
      display: flex;
      justify-content: center;
      align-items: center;
      &:last-child {
        margin-bottom: 0px;
      }
      .successIcon {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 90px;
          height: 90px;
        }
      }
      span {
        font-size: 16px;
        line-height: 26px;
        &.title {
          font-size: 18px;
          font-weight: 700;
        }
        &.neiRong {
          width: 460px;
          color: #989898;
        }
      }
      button {
        min-width: 183px;
        background: #1a73e8;
        padding: 0 40px;
        font-size: 16px;
        color: #ffffff;
        line-height: 20px;
        font-weight: 700;
        height: 48px;

        border-radius: 8px;
        border: 1px solid var(--Rakumart-500, #1a73e8);

        /* Shadows/shadow-xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        &:hover {
          border: 1px solid var(--Rakumart-700, #1355ab);
          background: var(--Rakumart-700, #1355ab);

          /* Shadows/shadow-xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
        &:active {
          border-radius: 8px;
          border: 1px solid var(--Rakumart-600, #1767d0);
          background: var(--Rakumart-600, #1767d0);

          /* Focus rings/ring-brand-shadow-xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
            0px 0px 0px 4px rgba(23, 103, 208, 0.23);
        }
      }
    }
    .jianyi {
      margin-top: 55px;
      font-size: 16px;
      line-height: 26px;
      color: #636363;
    }
    .staticPageLink {
      margin-top: 30px;
      display: flex;
      width: 787px;
      justify-content: space-between;
      align-items: center;
      transform: translateX(44px);

      .staticPageLinkOpt {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &::before {
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 11px;
          height: 11px;
          content: " ";
          font-size: 24px;
          background-color: #1a73e8;
          border-radius: 50%;
          text-decoration: none;
        }
        font-size: 16px;
        color: #636363;
        line-height: 1.1;
        text-decoration: underline;
      }
    }
  }
}
</style>
